<template>
  <v-container class="mt-14">
    <zip-code-checker></zip-code-checker>
    <vehicle-section></vehicle-section>
    <address-section></address-section>
  </v-container>
</template>

<script>
import ZipCodeChecker from '@/components/services/ZipCodeChecker.vue';
import VehicleSection from '@/components/services/VehicleSection.vue';
import AddressSection from '@/components/services/AddressSection.vue';

export default {
  name: 'AddressVehicleInformation',
  components: {
    ZipCodeChecker,
    VehicleSection,
    AddressSection,
  },
};
</script>
